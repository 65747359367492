import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { GENERATE_OTP, VERIFY_OTP } from "../../apiConst";
import InputField from "../../Common/InputField";
import { postRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";
import Navbar from "../Navbar/navbar";

const Login = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otpFieldDisplay, setOtpFieldDisplay] = useState(false);
  const [googleUser, setGoogleUser] = useState([]);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (googleUser && googleUser.access_token) {
      setIsLoading(true);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${googleUser.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          setProfile(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [googleUser]);

  useEffect(() => {
    if (profile) {
      const data = {
        email: profile.email,
      };
      loginViaEmailAndOTP(data);
    }
  }, [profile]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("userAuthToken")) {
      navigate(-1);
    }
  });

  const loginViaEmailAndOTP = (data) => {
    if (otpFieldDisplay) {
      data = {
        ...data,
        email: email,
      };
      postRequest(`${VERIFY_OTP}`, data)
        .then((res) => {
          reset();
          toast.success("Login Successfully");
          console.log(res);
          const { token } = res.data.data;
          if (token) {
            localStorage.setItem("userAuthToken", token);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          err.data && err.data.message && toast.error(err.data.message);
          console.log(err);
        });
    } else {
      setEmail(data.email);
      postRequest(`${GENERATE_OTP}`, data)
        .then((res) => {
          setOtpFieldDisplay(true);
          reset();
          toast.success("Otp Sent Successfully");
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          err.data && err.data.message && toast.error(err.data.message);
          console.log(err);
        });
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    loginViaEmailAndOTP(data);
  };

  const loginToGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <section className="h-screen">
      {isLoading && <FullScreenLoader />}
      {/* <Navbar /> */}
      <div className="container h-full px-6 py-24">
        <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
          <div className="mb-12 md:mb-0 md:w-8/12 lg:w-6/12">
            <img
              src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
              className="w-full"
              alt="Login"
            />
          </div>
          <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
            <form onSubmit={handleSubmit(onSubmit)}>
              {otpFieldDisplay ? (
                <div className="relative mb-6">
                  <InputField
                    fieldName="otp"
                    validationObj={{ required: "Please Enter Otp to Verify" }}
                    register={register}
                    errors={errors}
                    fieldType="text"
                    className="min-h-[auto] w-full rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    placeholder="Enter Otp Here"
                  />
                </div>
              ) : (
                <div className="relative mb-6">
                  <InputField
                    fieldName="email"
                    register={register}
                    errors={errors}
                    validationObj={{
                      required: "Please Enter Email or Phone Number",
                      validate: {
                        matchPattern: (e) =>
                          /^([0-9]{9})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$/.test(
                            e
                          ) || "Please Enter Valid Email or Phone Number",
                      },
                    }}
                    fieldType="text"
                    className="min-h-[auto] w-full rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    placeholder="Email or Phone Number"
                  />
                </div>
              )}

              {/* <div className="relative mb-6">
                <InputField
                  fieldName="password"
                  validationObj={{required: "Please Enter Password" }}
                  register={register}
                  errors={errors}
                  fieldType="password"
                  className="min-h-[auto] w-full rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                  placeholder="Password"
                />
              </div> */}

              <div className="w-100 flex flex-row items-center justify-center pb-3">
                <button
                  type="submit"
                  className="inline-block rounded bg-primary px-7 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={(e) => handleSubmit(onSubmit, e)}
                >
                  {otpFieldDisplay ? "Verify OTP" : "Sign in"}
                </button>
              </div>

              <div className="w-100 flex flex-row items-center justify-center pt-3">
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={() => loginToGoogle()}
                  className="mx-1 h-9 w-9 rounded-full bg-primary uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="mx-auto h-3.5 w-3.5"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"
                      id="mainIconPathAttribute"
                    ></path>
                  </svg>
                </button>

                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="mx-1 h-9 w-9 rounded-full bg-primary uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-auto h-3.5 w-3.5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </button>

                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="mx-1 h-9 w-9 rounded-full bg-primary uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-auto h-3.5 w-3.5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
