import React from "react";
import * as te from "tw-elements";
import Navbar from "./Components/Navbar/navbar";
import Routing from "./routes";
import Footer from "./Components/footer/Footer";
function App() {
  return (
    <>
      <Navbar />
      <Routing />
      {/* <Footer/> */}
      
    </>
  );
}

export default App;
