import { InfoWindow, Marker } from '@react-google-maps/api';
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router';
import markerFallback from './../../images/amazon.png';

const MapMarker = (props) => {
  const { place, onLoad, position } = props;
  console.log('🚀 ~ file: MapMarker.jsx:8 ~ MapMarker ~ place:', place);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const navigate = useNavigate();

  const goToCompanyPage = () => {
    navigate(`/${place.companyId}`);
  };

  const mapMarker = place?.logo ? place?.logo : markerFallback;

  return (
    <Marker
      onClick={goToCompanyPage}
      onLoad={onLoad}
      position={position}
      onMouseOver={() => setInfoWindowOpen(true)}
      icon={{
        url: mapMarker,
        scale: 2,
      }}
    >
      {infoWindowOpen && (
        <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
          <div className="tooltip text-center" onClick={goToCompanyPage}>
            <div className="text-lg">{place.title}</div>
            <div style={{ width: '100px', paddingLeft: '10px' }}>
              <Carousel
                autoPlay={true}
                showThumbs={false}
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                interval={3000}
                infiniteLoop={true}
              >
                {place.img.map((image, i) => {
                  return (
                    <div key={image + i}>
                      <img src={image} height={'auto'} alt={place.title} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default MapMarker;
