import React from "react";
import Turnstone from "turnstone";
import recentSearchesPlugin from "turnstone-recent-searches";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

function SearchComponent() {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    const company = data.search.toLowerCase();
    navigate(`/${company}`);
  };

  const listbox = {
    id: "companyId",
    name: "companyId",
    ratio: 8,
    displayField: "companyId",
    data: async (query) =>
      await axios
        .post(`https://api.itpros.tech/oj/company/search`, {
          companyId: query,
        })
        .then((res) => res)
        .then((result) => result.data.data),
    searchType: "startsWith",
  };

  const styles = {
    input:
      "relative m-0 block min-w-0 flex-auto h-16 rounded-tl-2xl rounded-bl-2xl bg-white bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none md:w-96 lg:w-[30rem] xl:w-[49rem]", // Adjusted width
    listbox: "bg-gray-200 w-full text-black rounded-md",
    highlightedItem: "bg-neutral-200",
    query: "text-oldsilver-800 placeholder:text-slate-600",
    typeahead: "text-slate-500",
    clearButton:
      "absolute inset-y-0 text-lg right-0 w-10 inline-flex items-center justify-center bg-netural-700 hover:text-red-500",
    noItems: "cursor-default text-center my-20",
    match: "font-semibold",
    groupHeading: "px-5 py-3 text-pink-500",
  };

  return (
    <form
      className="relative flex flex-wrap items-stretch justify-center w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="search"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Turnstone
            {...field}
            id="search"
            name="search"
            clearButton={true}
            noItemsMessage="We couldn't find any company that matches your search"
            listbox={listbox}
            styles={styles}
            maxItems={10}
            placeholder="Search for Companies, documents, forms..."
            plugins={[recentSearchesPlugin]}
          />
        )}
      />
      <button
        className="relative z-[2] flex items-center rounded-tr-2xl rounded-br-2xl bg-primary-700 px-6 py-1.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
        type="submit"
        id="button-addon1"
        data-te-ripple-init
        data-te-ripple-color="light"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="h-5 w-5"
        >
          <path
            fillRule="evenodd"
            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </form>
  );
}

export default SearchComponent;


