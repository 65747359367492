import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Components/Navbar/navbar";
const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem("userAuthToken");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      localStorage.removeItem("locationIp");
      return navigate("/login");
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      {isLoggedIn ? (
        <>
          {/* <Navbar />  */}
          {props.children}{" "}
        </>
      ) : null}
    </React.Fragment>
  );
};
export default ProtectedRoute;
