import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routing from "./routes";
import configureStore from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./css/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import App from "./App";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = configureStore({});

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_WEB_CLIENT_ID}>
    <Provider store={store}>
     <BrowserRouter>
     <App/>
     <ToastContainer theme="colored" />
     </BrowserRouter>
    
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();
