

export const contactFields = [
    {
        fieldName: 'firstName',
        fieldTitle: 'First Name*',
        placeholder: 'First Name',
        validationObj: {
            required: 'Please Enter First Name',
            // maxLength: {
            //     value: 52,
            //     message: 'Character Limit Exceeded, Max Allowed Is 52!',
            // },
        },
        fieldType: 'text',
    },

    {
        fieldName: 'lastName',
        fieldTitle: 'Last Name*',
        placeholder: 'Last Name',
        validationObj: {
            required: 'Please Enter Last Name',
        },
        fieldType: 'text',
    },


    {
        fieldName: 'contact',
        fieldTitle: 'Phone Number*',
        placeholder: 'Phone Number',
        validationObj: {
            required: 'Please Enter Phone Number',
        },
        fieldType: 'text',
    },

    {
        fieldName: 'email',
        fieldTitle: 'Email*',
        placeholder: 'Email',
        validationObj: {
            required: 'Please Enter Email',
        },
        fieldType: 'email',
    },
    {
        fieldName: 'message',
        fieldTitle: 'Message*',
        placeholder: 'Message',
        validationObj: {
            required: 'Please Enter Message',
            maxLength: {
                value: 250,
                message: 'Character Limit Exceeded,  Max Allowed Is 250!',
            },
        },
        fieldType: 'textarea',
    },
];