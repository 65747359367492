import React, { useState, useEffect } from "react";
import "../../css/table.css";
import { useForm } from "react-hook-form";
import InputField from "../../Common/InputField";
import { marginBreakdownFields } from "../Fields/marginbreakdown";
import { Grid, Paper, Button, Typography, Divider, Box } from "@mui/material";
import { IoMdSend, IoMdAdd } from "react-icons/io";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { postRequest } from "../interceptor/interceptor";
import { ADD_MARGIN } from "../../apiConst";
import { useNavigate } from "react-router";

export default function AddMargin() {
  const [dynamicFields, setDynamicFields] = useState([]);
  const [initialAmount, setInitialAmount] = useState(0); // Initial amount for balance calculation
  const [balance, setBalance] = useState(0); // Remaining balance after calculations
  const [item, setItem] = useState('')
  const fields = marginBreakdownFields;
  const [isLoading, setIsLoading] = useState(false);
  const navigate=useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  // Recalculate balance whenever dynamic fields or initial amount changes
  useEffect(() => {
    let total = initialAmount;
    dynamicFields.forEach((field) => {
      if (field.toggle) {
        total -= (initialAmount * field.value) / 100; // Handle percentage
      } else {
        total -= field.value; // Handle fixed value
      }
    });
    setBalance(total);
  }, [initialAmount, dynamicFields]);

  const onSubmit = (data) => {
    console.log("Submitted data:", data);
    setIsLoading(true)

    const dynamicPayload = dynamicFields.reduce((acc, field, index) => {
      console.log("acc, field, index",acc, field, index)
      const key = data[`name-${index + 1}`];
      let value = parseFloat(data[`value-${index + 1}`]);
      if (field.toggle) {
        value = `${value}%`; // Add percentage if toggle is on
      }
      acc[key] = value;
      return acc;
    }, {});

    const payload = {
      totalFund: data.totalFund,
      balance,
      ...dynamicPayload,
    };

    console.log("Payload", payload);
    postRequest(`${ADD_MARGIN}`, payload)
    .then((res) => {
      console.log("res",res);
      const { data} = res;
      setIsLoading(false);
      if (data)  {
        navigate('/margin-breakdown')
      }
    })
    .catch((error) => {
      setIsLoading(false);
      console.log(`Error`,error)
    });
  };

  const handleAddField = () => {
    setDynamicFields((prevFields) => [
      ...prevFields,
      { input1: "", input2: "", toggle: true, value: 0 },
    ]);
  };

  const handleAmountChange = (e) => {
    const amount = parseFloat(e.target.value) || 0;
    setInitialAmount(amount);
  };

  const handleToggleChange = (index) => {
    setDynamicFields((prevFields) =>
      prevFields.map((field, idx) =>
        idx === index ? { ...field, toggle: !field.toggle } : field
      )
    );
  };

  const handleValueChange = (index, e) => {
    const value = parseFloat(e.target.value) || 0;
    setDynamicFields((prevFields) =>
      prevFields.map((field, idx) =>
        idx === index ? { ...field, value: value } : field
      )
    );
  };

  const handleItemChange = (index, e) => {
    const item = e.target.value;
    setDynamicFields((prevFields) =>
      prevFields.map((field, idx) =>
        idx === index ? { ...field, [`name-${index+1}`]: item } : field
      )
    );
  };

  const isAddDisabled = balance > initialAmount || balance <= 0;
  const isSubmitDisabled = balance < 0 ||initialAmount===0
  return (
    <React.Fragment>
      {isLoading && <FullScreenLoader />}
      <div className="m-4">
        <Typography
          variant="h4"
          component="div"
          align="center"
          className="py-4 text-primary-600 font-extrabold"
        >
          Margin Calculator
        </Typography>
        <Paper elevation={3} className="p-4">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} justifyContent="space-between" alignItems='center'>
              {fields.map((field) => {
                const { fieldTitle, fieldName, fieldType,validationObj } = field;
                return (
                  <Grid item xs={4} sm={4} key={fieldName}>
                    <label className="block text-gray-500 font-bold mb-1">
                      {fieldTitle}
                    </label>
                    <InputField
                      fieldName={fieldName}
                      register={register}
                      errors={errors}
                      onChange={handleAmountChange}
                      fieldType={fieldType}
                      validationObj={validationObj}
                      placeholder={fieldTitle}
                      
                    />
                  </Grid>
                );
              })}

              <Grid item xs={4} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isAddDisabled}
                  startIcon={<IoMdAdd />}
                  onClick={handleAddField}
                >
                  Add
                </Button>
              </Grid>
            </Grid>

            {/* Dynamic Fields */}
            {dynamicFields.map((field, index) => (
              <Grid container spacing={2} key={index} className="mb-4">
                <Grid item xs={12} sm={4}>
                  <label className="block text-gray-500 font-bold mb-1">
                    Item
                  </label>
                  <InputField
                    fieldName={`name-${index + 1}`}
                    register={register}
                    errors={errors}
                    fieldType="text"
                    placeholder="Item"
                    onChange={(e) => handleItemChange(index, e)}
                    
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <label className="block text-gray-500 font-bold mb-1">
                    Value
                  </label>
                  <InputField
                    fieldName={`value-${index + 1}`}
                    register={register}
                    errors={errors}
                    fieldType="number"
                    placeholder="Value"
                    onChange={(e) => handleValueChange(index, e)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className="toggle mt-7">
                    <label className="name">Fixed</label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id={`checkbox-${index}`}
                      checked={field.toggle}
                      onChange={() => handleToggleChange(index)}
                    />
                    <label htmlFor={`checkbox-${index}`} className="label">
                      <div className="ball"></div>
                    </label>
                    <label className="name">Percentage</label>
                  </div>
                </Grid>
              </Grid>
            ))}

            {/* Balance Display */}
            <div className="mb-4">
              <Typography variant="body1">
                Balance: <strong>{balance.toFixed(2)}</strong>
              </Typography>
            </div>

            {/* Buttons */}
            <div className="flex justify-center space-x-4">
              <Grid item>
                <Button variant="contained" color="error" className="mt-5" onClick={()=>navigate('/margin-breakdown')}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitDisabled}
                  className="mt-5"
                >
                  Submit
                </Button>
              </Grid>
            </div>
          </form>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} className="p-4">
              <Typography variant="h6" className="font-bold mb-4">
                Summary
              </Typography>
              <Divider className="mb-4" />
              <Typography variant="body1" className="mb-4">
                <strong>Total Amount:</strong> {initialAmount.toFixed(2)}
              </Typography>
              <Typography variant="body1" className="mb-4">
                <strong>Balance:</strong> {balance.toFixed(2)}
              </Typography>
              {dynamicFields.length > 0 && (
                <Box>
                  <Typography variant="h6" className="mb-2">
                    Breakdown
                  </Typography>
                  <ul className="list-disc ml-6">
                    {dynamicFields.map((field, index) => {
                      // Extract the item name and value from the field object
                      console.log('field', field)
                     
                      const itemName = field[`name-${index+1}`]
                      const itemValue = field.toggle
                        ? `${field.value}%`
                        : field.value; // Show percentage or fixed value

                      return <li key={index}>{`${itemName}: ${itemValue}`}</li>;
                    })}
                  </ul>
                </Box>
              )}
            </Paper>
          </Grid>
          </Grid>
        </Paper>
      </div>
    </React.Fragment>
  );
}
