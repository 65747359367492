import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const [show, setShow] = useState(false);
  const [showTool, setShowTool] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (localStorage.getItem("userAuthToken")) {
      setIsLogin(true);
    }
  }, [params]);

  const onClickDropDown = () => {
    setShow(!show);
  };

  const onClickToolDropDown = () => {
    setShowTool(!showTool);
  };

  const mobileMenu = () => {
    setShowMobileNav(!showMobileNav);
  };

  const handleLogout = () => {
    localStorage.removeItem("userAuthToken");
    localStorage.removeItem("locationIp");
    navigate("/login");
  };

  return (
    <nav className="bg-primary-900 border-gray-200 lg:px-48 sm:px-4 py-2.5 shadow-md">
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <Link to="/" className="flex items-center pl-1 md:pl-0">
          <span className="self-center text-2xl font-semibold whitespace-nowrap text-white font-roboto-mono">
            ITPROS.TECH
          </span>
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={mobileMenu}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        {/*Mobile Nav*/}
        {showMobileNav ? (
          <div className="w-full md:hidden" id="navbar-default">
            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link
                  // to="https://itpros.tech"
                  to="/"
                  // target="_blank"
                  className="block py-2 pl-3 pr-4 font-bold text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                >
                  Home
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/forms"
                  // target="_blank"
                  className="block py-2 pl-3 pr-4 font-bold text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                >
                  Forms
                </Link>
              </li> */}
              <li>
                <Link
                  to="/e/company"
                  // target="_blank"
                  className="block py-2 pl-3 pr-4 font-bold text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                >
                  Geo search
                </Link>
              </li>
              {/* <li>
                <button
                  className="flex items-center justify-between w-full py-2 pl-3 pr-4 font-bold text-gray-800 rounded hover:bg-gray-100  md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono"
                  onClick={onClickDropDown}
                >
                  Products{" "}
                  <svg
                    className="w-5 h-5 ml-1"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
                {show ? (
                  <div className="z-10 absolute font-normal bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 w-44">
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-400">
                      <li>
                        <Link
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          to="/form-builder"
                        >
                          Form Builder
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          to="/e/company"
                        >
                          Company Search
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          to="/margin-breakdown"
                        >
                          Margin Breakdown
                        </Link>
                      </li>
                      <li>
                        <button
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          to="/data"
                        >
                          Upload File
                        </button>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </li> */}
              <li>
                <button
                  className="flex items-center justify-between w-full py-2 pl-3 pr-4 font-bold text-gray-800 rounded hover:bg-gray-100  md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono"
                  onClick={onClickToolDropDown}
                >
                  Tools{" "}
                  <svg
                    className="w-5 h-5 ml-1"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
                {showTool ? (
                  <div className="z-10 absolute font-normal bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 w-44">
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-400">
                      <li>
                        <Link
                          className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          to="/margin-breakdown"
                        >
                          Margin Breakdown
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </li>
              <a
                href={`mailto:${"contact@itpros.tech"}`}
                className="flex items-center text-gray-800"
              >
                <span className="ml-1 mt-2 mb-3 font-semibold hover:text-blue-700">
                  contact@itpros.tech
                </span>
              </a>

              {isLogin ? (
                <li>
                  <button
                    onClick={handleLogout}
                    className="ml-3 py-2 pl-3 pr-4 border-2 h-7 flex justify-center items-center w-16 border-danger text-danger-700  rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                  >
                    Logout
                  </button>
                </li>
              ) : (
                <li>
                  <Link
                    to="/login"
                    className="ml-3 py-2 pl-3 pr-4 border-2 h-7 flex justify-center items-center w-14 border-primary text-primary-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                  >
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>
        ) : null}
        {/*Mobile Nav*/}
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <Link
                // to="https://itpros.tech"
                to="/"
                // target="_blank"
                className="flex items-center leading-7 py-2 pl-3 pr-4 font-bold text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
              >
                Home
              </Link>
            </li>
            {/* <li>
              <Link
                to="/forms"
                // target="_blank"
                className="flex items-center leading-7 py-2 pl-3 pr-4 font-bold text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
              >
                Forms
              </Link>
            </li> */}
            <li>
              <Link
                 to="/e/company"
                // target="_blank"
                className="flex items-center leading-7 py-2 pl-3 pr-4 font-bold text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
              >
                Geo search
              </Link>
            </li>
            {/* <li>
              <button
                className="flex items-center leading-7 justify-between w-full py-2 pl-3 pr-4 font-bold text-gray-800 rounded hover:bg-gray-100  md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono"
                onClick={onClickDropDown}
              >
                Products{" "}
                <svg
                  className="w-5 h-5 ml-1"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              {show ? (
                <div className="z-10 absolute font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                  <ul className="py-2 text-sm text-gray-700 dark:text-gray-400">
                    <li>
                      <Link
                        className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        to="/form-builder"
                        // to="https://form.itpros.tech"
                        // target="_blank"
                      >
                        Form Builder
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        to="/e/company"
                      >
                        Company Search
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        to="/data"
                      >
                        Upload File
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : null}
            </li> */}
            <li>
              <button
                className="flex items-center justify-between w-full mt-1 py-2 pl-3 pr-4 font-bold text-gray-800 rounded hover:bg-gray-100  md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono"
                onClick={onClickToolDropDown}
              >
                Tools{" "}
                <svg
                  className="w-5 h-5 ml-1"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              {showTool ? (
                <div className="z-10 absolute font-normal bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 w-44">
                  <ul className="py-2 text-sm text-gray-700 dark:text-gray-400">
                    <li>
                      <Link
                        className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        to="/margin-breakdown"
                      >
                        Margin Breakdown
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : null}
            </li>

            <a
              href={`mailto:${"contact@itpros.tech"}`}
              className="flex items-center text-gray-800"
            >
              <span className="ml-1 font-semibold hover:text-blue-700">
                contact@itpros.tech
              </span>
            </a>

            {isLogin ? (
              <li>
                <button
                  onClick={handleLogout}
                  className="py-2 pl-3 pr-4 border-2 h-7 flex justify-center items-center w-14 border-danger text-danger-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-red-700 md:p-0 dark:text-danger-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                >
                  Logout
                </button>
              </li>
            ) : (
              <li>
                <Link
                  to="/login"
                  className="py-2 pl-3 pr-4 border-2 h-7 flex justify-center items-center w-14 border-primary text-primary-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-700 md:p-0 dark:text-danger-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                >
                  Login
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
