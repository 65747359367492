// HeroSearch.jsx
import React from "react";
import SearchComponent from "./SearchComponent";
import heroBg from "../assets/hero-bg.svg";
import bannerBg from '../images/bannerBg.png'

function HeroSearch() {
  return (
    <header
      className="relative w-full bg-blue-50 h-[28rem] flex flex-col justify-center items-center text-center text-white"
      // style={{
      //   backgroundImage: `url(${heroBg})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      //   backgroundBlendMode: "overlay",
      //   backgroundColor: "rgba(0,0,0,0.6)",
      // }}
    >
      {/* Heading Section */}
      <h1 className="text-3xl text-gray-900  md:text-4xl lg:text-5xl font-extrabold mb-8">
        Connecting IT companies and professionals <br /> <span className="text-orange-500">across the globe.</span>
      </h1>
      <p className="max-w-xl text-gray-900 mx-auto text-lg md:text-xl lg:text-2xl font-light mb-12">
        Discover, Connect, and Collaborate Seamlessly
      </p>

      {/* Search Section */}
      <div className="w-full flex justify-center px-6">
        <SearchComponent />
      </div>
    </header>
  );
}

export default HeroSearch;
