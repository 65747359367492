import React, { useEffect } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../../css/table.css";
import { get, isEmpty } from "lodash";
import { getComparator, stableSort, TableComponent } from "../../Common/Table";
import { headCells } from "./headCells";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useNavigate } from "react-router";
import { useState } from "react";
import { postRequest } from "../interceptor/interceptor";
import { GET_COMPANY_DETAILS } from "../../apiConst";
import { useSearchParams } from "react-router-dom";
import Navbar from "../Navbar/navbar";

export default function CompanyTable(props) {
  const [order, setOrder] = useState("asc");
  const [tableRowsData, setTableRowsData] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchVal, setSearchVal] = useState("");
  const [totalCount, setTotalCount] = useState(rowsPerPage);
  const [archiveDataDisplay, setArchiveDataDisplay] = useState(false);
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  const getAllData = (pg = 1, size = 10, search = "", archiveFlag = false) => {
    setIsLoading(true);
    const payload = {
      title: search,
    };
    postRequest(
      `${GET_COMPANY_DETAILS}/search?page=${pg}&size=${size}
      `,
      payload
    )
      .then((res) => {
        if (res && res.data.data.length > 0) {
          if (res.data.data.length === 1) {
            navigate(`/${get(res.data.data[0], "companyId")}`);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setTableRowsData(res.data.data);
            setTotalCount(res.data.total);
          }
        } else {
          setIsLoading(false);
          setPage(0);
          setTotalCount(0);
          setTableRowsData([]);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const value = searchParams.get("val");
    if (value) {
      setSearchVal(value);
      getAllData(1, 10, value);
    } else {
      getAllData();
    }
  }, []);

  const handleClick = (event, row) => {
    setSelected(row);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await getAllData(Number(newPage + 1), rowsPerPage, "", archiveDataDisplay);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    getAllData(1, parseInt(event.target.value), "", archiveDataDisplay);
  };

  const handleView = (row) => {
    navigate(`/${row.companyId}`);
  };

  const backToHome = () => {
    navigate("/");
  };

  return (
    <React.Fragment>
      {isLoading && <FullScreenLoader />}
      {/* <Navbar /> */}
      <div className="m-3 flex justify-end">
        <button
          type="button"
          className="inline-block mr-10 absolute cursor-pointer rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          onClick={backToHome}
        >
          Back
        </button>
      </div>
      <div className="mx-2 shadow-lg" style={{ width: "99%", display: "flex" }}>
        <div style={{ width: "99%" }}>
          <TableComponent
            numSelected={isEmpty(selected) ? 0 : 1}
            searchVal={searchVal}
            headingText={"Company Details"}
            order={order}
            orderBy={orderBy}
            rowCount={tableRowsData.length || 0}
            tableRowsData={tableRowsData}
            selected={selected}
            headCells={headCells}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setSearchVal={setSearchVal}
            rowsPerPage={rowsPerPage}
            getAllData={getAllData}
            archiveDataDisplay={archiveDataDisplay}
          >
            <TableBody className="table-body">
              {tableRowsData &&
                tableRowsData.length > 0 &&
                stableSort(tableRowsData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const labelId = `enhanced-table-radio-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="radio"
                        name="selectedRow"
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell></TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell>{row?.companyDetails.email}</TableCell>
                        <TableCell>
                          {row.isActive ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell>{row?.companyDetails.country}</TableCell>
                        <TableCell>
                          <button
                            className="rounded border-2 border-primary text-primary px-4 pt-1 pb-1"
                            onClick={() => handleView(row)}
                          >
                            View
                          </button>{" "}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </TableComponent>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Entries per page: "}
            labelDisplayedRows={({ from, to, count }) =>
              `Showing ${from}-${to} of ${count} entries`
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}
